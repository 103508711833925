<template>
  <div>
    <ModuleForm
      :module-name="$tc('moduleForm.breakingnews')"
      :show-header-info="disabled"
    >
      <template #form-title>
        {{ $t(`route.${$route.name}`) }}
      </template>
      <template #header-buttons>
        <ModuleFormButton
          v-if="isEditMode"
          :title="$tc('breakingnews.publish_news_label')"
          @click="handlePublish"
          :disabled="!isPublishAvailable"
        />
        <ModuleFormButton
          v-if="isEditMode"
          :title="$tc('buttons.unpublish')"
          @click="handleUnpublish"
          :disabled="isPublishAvailable"
        />
        <ModuleFormButton
          v-if="disabled"
          :required-permissions="requiredPermissions.editButton"
          icon="edit"
          @click="goToEdit"
        />
        <ModuleFormButton
          v-else
          :title="$tc('buttons.save')"
          :required-permissions="requiredPermissions.saveButton"
          @click="save"
        />
        <ModuleFormDeleteButton
          v-if="breakingnews.id && isPublishAvailable"
          :record-id="breakingnews.id"
          :required-permissions="requiredPermissions.deleteButton"
          store-name="breakingnews"
          list-router-path="breakingnews"
        />
        <ModuleFormButton
          icon="close"
          @click="$router.push('/breakingnews')"
        />
      </template>
      <template #form-footer>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <Input
                v-model="breakingnews.title"
                @blur="$v.breakingnews.title.$touch()"
                :error="$v.breakingnews.title.$error"
                id="breakingnews_title"
                :label="$tc('breakingnews.form.title_label')"
                :placeholder="$tc('breakingnews.form.title_placeholder')"
                :disabled="disabled"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <Input
                v-model="breakingnews.text"
                @blur="$v.breakingnews.text.$touch()"
                :error="$v.breakingnews.text.$error"
                id="breakingnews_text"
                :label="$tc('breakingnews.form.message_text_label')"
                :placeholder="$tc('breakingnews.form.message_text_placeholder')"
                :disabled="disabled"
                required
              />
            </div>
          </div>
          <div
            class="col-md-2"
          >
            <div class="form-group">
              <span class="input-group-append__label">{{ $t('input_char_count') }}</span>
              <span
                class="input-group-text text-center"
                :class="{ 'input-group-text--error': !$v.breakingnews.combinedMaxLength }"
              >{{ titleTextCharCount }} {{ $t('input_char_delimiter') }} {{ maxCharCount }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <Input
              v-model="breakingnews.linkUrl"
              @blur="$v.breakingnews.linkUrl.$touch()"
              :error="$v.breakingnews.linkUrl.$error"
              id="breakingnews_linkUrl"
              :label="$tc('breakingnews.form.link')"
              :placeholder="$tc('breakingnews.form.link_placeholder')"
              :disabled="disabled"
              input-type="url"
            />
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <label class="input-area__label" for="breakingnews_createdAt">
                  {{ $tc('system.created_at') }}
                </label>
                <Datepicker
                  v-model="breakingnews.createdAt"
                  id="breakingnews_createdAt"
                  no-label
                  disable-form-group
                  :disabled="true"
                >
                </Datepicker>
              </div>
              <div class="col-6">
                <label class="input-area__label" for="breakingnews_modifiedAt">
                  {{ $tc('system.modified_at') }}
                </label>
                <Datepicker
                  v-model="breakingnews.modifiedAt"
                  id="breakingnews_modifiedAt"
                  no-label
                  disable-form-group
                  :disabled="true"
                >
                </Datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="breakingnews-multiselect">
          <div class="breakingnews-multiselect__multiselect">
            <MultiSelect
              v-model="sitesSelectValue"
              :options="[]"
              :internal-search="false"
              :show-no-results="false"
              :searchable="false"
              :disable-opening="true"
              label="title"
              track-by="id"
              id="breakingnews_siteIds"
              :label-desc="$tc('modal.journalsselection.sites_label')"
              :error="$v.sitesSelectValue.$error"
              :disabled="isEditMode"
              required
            />
          </div>
          <button
            class="breakingnews-multiselect__btn"
            @click="$refs.journalsSelectionModal.open()"
          >{{ $tc('modal.journalsselection.sites_button_label') }}</button>
        </div>
      </template>
    </ModuleForm>
    <app-journals-selection-modal
      ref="journalsSelectionModal"
      @onSelectedSites="handleSelectedSites"
      :defaultSelectedSiteIds="breakingnews.siteIds"
      :permissionModule="permissionModule"
    ></app-journals-selection-modal>
  </div>
</template>

<script>
import { isURL } from '@/filters'
import { maxLength, minLength, required, helpers } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import MultiSelect from '@/components/form/select/MultiSelect'
import BreakingNewsModel from '@/model/BreakingNewsModel'
import NotifyService from '@/services/NotifyService'
import Datepicker from '@/components/form/Datepicker'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import PermissionService from '@/services/PermissionService'
import JournalsSelectionModal from '@/components/shared/JournalsSelectionModal'
import { MODULE_MODULES_BREAKING_NEWS } from '@/model/ValueObject/UserPermissionModules'
import { mapGetters } from 'vuex'

export default {
  name: 'BreakingNewsNewView',
  data () {
    return {
      isEditMode: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.BREAKING_NEWS_PERMISSIONS,
      breakingnews: this._.cloneDeep(BreakingNewsModel),
      permissionModule: MODULE_MODULES_BREAKING_NEWS,
      maxCharCount: 110
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    const combinedMaxLength = helpers.withParams(
      { type: 'maxCharCount', max: this.maxCharCount },
      function (value) {
        if (!value) return true

        const titleLength = value.title ? value.title.length : 0
        const textLength = value.text ? value.text.length : 0
        return (titleLength + textLength) <= this.maxCharCount
      }
    )
    return {
      breakingnews: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(110)
        },
        text: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(110)
        },
        linkUrl: {
          required: false,
          isURL: helpers.withParams({ type: 'url' }, value => !value || isURL(value))
        },
        combinedMaxLength
      },
      sitesSelectValue: {
        required
      }
    }
  },
  components: {
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    MultiSelect,
    Datepicker,
    ModuleForm,
    appJournalsSelectionModal: JournalsSelectionModal
  },
  computed: {
    ...mapGetters(['vlm']),
    enabledSites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    sitesSelectValue: {
      get () {
        if (this.breakingnews.siteId && this.enabledSites.length) {
          return this.enabledSites.find(site => site.id === this.breakingnews.siteId) || []
        }

        return this.breakingnews.siteIds
          ? this.breakingnews.siteIds.map(siteId => this.enabledSites.find(site => site.id === siteId))
          : []
      },
      set (value) {
        if (value) {
          this.breakingnews.siteIds = value.map(site => site.id)
        } else {
          this.breakingnews.siteIds = []
        }
      }
    },
    isPublishAvailable () {
      return this.breakingnews.publishedTo !== null
    },
    titleTextCharCount () {
      return this.breakingnews.title.length + this.breakingnews.text.length
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        if (!this.$v.breakingnews.combinedMaxLength) {
          NotifyService.setErrorMessage(this.$t('notify.please_check_max_text_length'))
        }
        return
      }

      this.$store.dispatch('breakingnews/create', {
        title: this.breakingnews.title,
        text: this.breakingnews.text,
        linkUrl: this.breakingnews.linkUrl,
        siteIds: this.breakingnews.siteIds
      })
        .then(() => {
          if (this.$store.getters['breakingnews/error'] === null) {
            this.goToLastView()
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['breakingnews/error'])
          }
        })
        .catch(error => console.error(error))
    },
    handleSelectedSites (sites) {
      this.breakingnews.siteIds = sites.map(site => site.id)
    },
    goToEdit () {
      this.$router.push('/breakingnews/' + this.breakingnews.id + '/edit')
    },
    goToLastView () {
      this.$router.push('/breakingnews')
    },
    handleUnpublish () {
      this.$store.dispatch('breakingnews/unpublish', this.breakingnews.id)
        .then(() => {
          this.goToLastView()
          NotifyService.setSuccessMessage(this.$t('notify.record_was_unpublished'))
        })
        .catch(error => {
          NotifyService.setErrorMessage(error)
        })
    },
    handlePublish () {
      this.$store.dispatch('breakingnews/publish', this.breakingnews.id)
        .then(() => {
          this.goToLastView()
          NotifyService.setSuccessMessage(this.$t('notify.record_was_published'))
        })
        .catch(error => {
          NotifyService.setErrorMessage(error)
        })
    }
  }
}
</script>
<style scoped lang="scss">
.breakingnews-multiselect {
  display: flex;
  justify-content: space-between;

  &__multiselect {
    flex: 1 1 0;
  }
  &__btn {
    flex: 0 0 auto;
    padding-left: rem(25px);
    padding-right: rem(25px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #fff;
    background: #6599FE;
    border: none;
    border-radius: 0 rem(6px) rem(6px) 0;
    cursor: pointer;
    height: rem(43px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    align-self: end;
    &:hover {
      background: darken(#6599FE, 10%);
    }
  }
}
.form-content {
  &__left {
    ::v-deep & {
      flex: 1 1 0;
    }
  }
}
</style>
